<template>
    <transition name="slide" appear>
        <ul class="error-list" v-if="Array.isArray(errors)">
            <li v-for="(error, $index) in errors" :key="$index" class="field-error" v-html="error.message">
            </li>
        </ul>
        <p v-else class="field-error" v-html="errors.message"></p>
    </transition>
</template>
<script>
    export default {
        props: {
            errors: {
                type: [Array, Object],
                required: true
            }
        }
    }
</script>