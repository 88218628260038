<template functional>
    <div class="base-alert" :class="[data.staticClass, props.type]">
        <BaseIcon v-if="props.icon" name="alert"/>
        <slot></slot>
    </div>
</template>
<script>
    export default {
        name: 'BaseAlert',
        props: {
            type: String,
            icon: {
                type: Boolean,
                default: true
            }
        }
    }
</script>
<style lang="scss" scoped>
    .base-icon{
        vertical-align: -2px;
        margin-right: 0.2rem;
    }
</style>