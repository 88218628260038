var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "slide", appear: "" } }, [
    Array.isArray(_vm.errors)
      ? _c(
          "ul",
          { staticClass: "error-list" },
          _vm._l(_vm.errors, function (error, $index) {
            return _c("li", {
              key: $index,
              staticClass: "field-error",
              domProps: { innerHTML: _vm._s(error.message) },
            })
          }),
          0
        )
      : _c("p", {
          staticClass: "field-error",
          domProps: { innerHTML: _vm._s(_vm.errors.message) },
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }