var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [_c("router-view")],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "scroll-top",
          class: { visible: _vm.scrollTopVisible },
          attrs: { "aria-label": "Scroll to top", type: "button" },
          on: { click: _vm.scrollToTop },
        },
        [_c("BaseIcon", { attrs: { name: "caret_up" } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }