function setCssVars(data) {
    const darkMode = document.getElementsByTagName('html')[0].attributes['data-dark-mode']?.value || false;
    const root = document.documentElement;
    const rgb = hexToRgb(data.main_color);

    const styles = {
        mainColor: { name: '--brand-color', data: data.main_color },
        mainRGB: { name: '--main-rgb', data: `${rgb.r},${rgb.g},${rgb.b}`}
    }

    if(darkMode === 'true'){
        let color = data?.dark_mode_text_color ? data.dark_mode_text_color : '#eeeeee';
        styles.mainColor.data = color;
        styles.textDark = {name: '--text-color-2', data: color};
    }
    Object.keys(styles).forEach(key => root.style.setProperty(styles[key].name, styles[key].data) );
}

function setFavicon(data){
    const link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = data.favicon;
    document.getElementsByTagName('head')[0].appendChild(link);
}

function setTitle(data){
    document.getElementsByTagName('head')[0].getElementsByTagName('title')[0].innerText = `${data.app_name} | ${data.full_app_name}`
}

function hexToRgb(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
}

const userSetDarkMode = () => JSON.parse(localStorage.getItem('darkMode'));
const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

darkModeMediaQuery.addListener((e) => {
    const darkMode = e.matches;
    if (userSetDarkMode() === null){
        document.documentElement.dataset.darkMode = darkMode;
    }
});

userSetDarkMode() === null
    ? document.documentElement.dataset.darkMode = darkModeMediaQuery.matches
    : document.documentElement.dataset.darkMode = userSetDarkMode();


function currentTheme() {
    const data = window.brandingSettings;
    data.default = !window.isBranded;
    setFavicon(data);
    // setCssVars(data);
    setTitle(data);
    return data;
}

export default currentTheme();