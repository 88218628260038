<template>
    <div>
        <transition name="fade">
            <div v-show="hasModals" class="modal-container" is="transition-group" name="modal" mode="out-in">
                <div class="modal-box" tabindex="-1" role="dialog" v-for="(modal, $index) in modals" :key="modal.title" @keydown.esc="!modal.hasOwnProperty('alert') && !modal.confirm ? close($index) : null">
                    <div class="modal-content" :class="{'prompt': Boolean(modal.prompt), [modal.id]: true }">
                        <span v-if="!modal.hasOwnProperty('alert') && !modal.confirm" @click="close($index)" class="close">
                            <BaseIcon name="close" size="10"/>
                        </span>
                        <p class="modal-title mb-1">{{modal.title}}</p>
                        <p v-if="modal.subtitle" class="modal-subtitle form-description">{{modal.subtitle}}</p>
                        <component :is="modal.loadedComponent" :data="modal.props" :schema="modal.form"/>
                        <div v-if="modal.prompt">
                            <template v-if="modal.input">
                                <form @submit.prevent="handleConfirm(modal.confirm, $refs.promptInput[0].value)">
                                    <FormField>
                                        <label v-text="modal.input.label"></label>
                                        <input :type="modal.input.type || 'text'" ref="promptInput" required>
                                    </FormField> 
                                
                                    <button type="submit">Confirm</button>
                                    <button type="button" class="secondary" @click="handleConfirm(modal.cancel, false)">Cancel</button>
                                </form> 
                            </template>

                            <div class="modal-footer flex" v-else-if="modal.alert">
                                <button @click="routerLink(modal.alert.back)">{{modal.alert.back.title}}</button>
                                <button @click="routerLink(modal.alert.next)">{{modal.alert.next.title}}</button>
                            </div>

                            <div class="modal-footer flex" v-else>
                                <button @click="handleConfirm(modal.confirm, true)">Confirm</button>
                                <button class="secondary" @click="handleConfirm(modal.cancel, false)">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>       
    </div>
</template>
<script>
    export default {
        data() {
            return {
                modals: []
            };
        },
        computed: {
            hasModals(){
                const hasModals = !!this.modals.length;
                hasModals
                    ? document.body.classList.add('no-scroll')
                    : document.body.classList.remove('no-scroll');

                return hasModals;
            }
        },
        methods: {
            focusModal(modal){
                let modalClass = document.querySelector(`.${modal}`);
                if(modalClass) modalClass.focus();
            },
            open(options){
                if(!options) return;            
                
                if(options.component){
                    if(typeof options.component === 'string'){
                        options.loadedComponent = () => import(`../components/modals/${options.component}.vue`);
                    }else{
                        options.loadedComponent = options.component;
                    }
                }

                if(!options.id && options.title){
                    options.id = removeSymbols(camelize(options.title));
                }

                let existIndex = this.modals.findIndex(item => item.id === options.id);

                if(existIndex > -1){
                    this.modals.splice(existIndex, 1);
                    this.modals.push(options);
                }
                else{
                    this.modals.push(options);   
                }
                setTimeout(() => this.focusModal(options.id));
            },
            close(index){
                if(this.modals[index]?.onClose) this.modals[index]?.onClose();                
                this.modals.pop();
            },
            confirm(message, options){      
                return new Promise((resolve, reject) => {
                    let modalOptions = {
                        title: message,
                        prompt: true,
                        confirm: resolve,
                        cancel: reject,
                        subtitle: options?.subtitle,
                        input: options?.input
                    };

                    if(options?.alert){
                        modalOptions.alert = options.alert;
                    }

                    this.open(modalOptions);
                });
            },
            handleConfirm(promise, value){
                return promise(value), this.close();
            },
            routerLink(link){
                this.$router.push(link.router);
                this.$modals.close();
            }         
        }
    }
    function camelize(str) {
        return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
    }
    function removeSymbols(string){
        return string.replace(/[&\\/\\#,+()$~%.'":*?<>{}]/g, '');
    }

</script>
<style lang="scss" scoped>
    .modal-container{
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: var(--modal-backdrop);
        z-index: 10;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: fade-in 0.2s;
    }
    .modal-box{
        position: absolute;
        background: var(--bg-color);
        border: 1px solid var(--border-color);
        border-radius: 4px;
        box-shadow: var(--shadow);
        margin: auto;
        grid-column: 1;
        grid-row: 1;
        z-index: inherit;
        animation: fade-in 0.2s;
        resize: both;
        max-height: 100%;
        .table-wrapper{
            max-height: 70vh;
        }
    }
    .modal-content{
        max-height: 95vh;
        max-width: 95vw;
        min-width: 420px;
        overflow: auto;
        padding: 1.7rem;
        .close{
            position: absolute;
            right: .3rem;
            top: .3rem;
            transition: .2s all;
            &:hover{
                cursor: pointer;
                opacity: .7;
            }
        } 
    }
    .modal-title{
        margin-top: 0;
        font-weight: 600;
        letter-spacing: 0.02rem;
        font-size: 1.45rem;
        max-width: 550px;
    }
    .modal-footer{
        padding-top: 1rem;
        text-align: right;
        >*+*{
            margin-left: 0.5rem;
        }
    }
    @keyframes fade-in{
        from { opacity: 0; }
        to { opacity: 1; }
    }
</style>