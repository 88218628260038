var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass: "base-alert",
      class: [_vm.data.staticClass, _vm.props.type],
    },
    [
      _vm.props.icon ? _c("BaseIcon", { attrs: { name: "alert" } }) : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }