var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "base-icon",
      class: [_vm.$attrs.class, _vm.name],
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: _vm.getViewbox(_vm.name),
        height: _vm.size,
        width: _vm.size,
      },
    },
    [
      _c("title", { domProps: { textContent: _vm._s(_vm.$attrs.title) } }),
      _vm._v(" "),
      _vm._l(_vm.getPath(_vm.name), function (path, $index) {
        return _c("path", {
          key: $index,
          attrs: {
            d: path,
            stroke: _vm.color,
            fill: "none",
            "stroke-width": "2",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }