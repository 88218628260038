var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c(
            "transition-group",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasModals,
                  expression: "hasModals",
                },
              ],
              tag: "div",
              staticClass: "modal-container",
              attrs: { name: "modal", mode: "out-in" },
            },
            _vm._l(_vm.modals, function (modal, $index) {
              return _c(
                "div",
                {
                  key: modal.title,
                  staticClass: "modal-box",
                  attrs: { tabindex: "-1", role: "dialog" },
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      )
                        return null
                      !modal.hasOwnProperty("alert") && !modal.confirm
                        ? _vm.close($index)
                        : null
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "modal-content",
                      class: {
                        prompt: Boolean(modal.prompt),
                        [modal.id]: true,
                      },
                    },
                    [
                      !modal.hasOwnProperty("alert") && !modal.confirm
                        ? _c(
                            "span",
                            {
                              staticClass: "close",
                              on: {
                                click: function ($event) {
                                  return _vm.close($index)
                                },
                              },
                            },
                            [
                              _c("BaseIcon", {
                                attrs: { name: "close", size: "10" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p", { staticClass: "modal-title mb-1" }, [
                        _vm._v(_vm._s(modal.title)),
                      ]),
                      _vm._v(" "),
                      modal.subtitle
                        ? _c(
                            "p",
                            { staticClass: "modal-subtitle form-description" },
                            [_vm._v(_vm._s(modal.subtitle))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(modal.loadedComponent, {
                        tag: "component",
                        attrs: { data: modal.props, schema: modal.form },
                      }),
                      _vm._v(" "),
                      modal.prompt
                        ? _c(
                            "div",
                            [
                              modal.input
                                ? [
                                    _c(
                                      "form",
                                      {
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                            return _vm.handleConfirm(
                                              modal.confirm,
                                              _vm.$refs.promptInput[0].value
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("FormField", [
                                          _c("label", {
                                            domProps: {
                                              textContent: _vm._s(
                                                modal.input.label
                                              ),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("input", {
                                            ref: "promptInput",
                                            refInFor: true,
                                            attrs: {
                                              type: modal.input.type || "text",
                                              required: "",
                                            },
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          { attrs: { type: "submit" } },
                                          [_vm._v("Confirm")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "secondary",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleConfirm(
                                                  modal.cancel,
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Cancel")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : modal.alert
                                ? _c(
                                    "div",
                                    { staticClass: "modal-footer flex" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.routerLink(
                                                modal.alert.back
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(modal.alert.back.title))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.routerLink(
                                                modal.alert.next
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(modal.alert.next.title))]
                                      ),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "modal-footer flex" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleConfirm(
                                                modal.confirm,
                                                true
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Confirm")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "secondary",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleConfirm(
                                                modal.cancel,
                                                false
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Cancel")]
                                      ),
                                    ]
                                  ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }